import { fabric } from "fabric"

export default class ROIBox {
  constructor({
    id,
    name = "",
    canvas,
    left,
    top,
    width,
    height,
    onChange,
    onObjectSelect,
    color,
    date,
    fontSize = 32,
    enableMiddleControls = false,
  }) {
    this.id = id
    this.name = name
    this.canvas = canvas
    this.onChange = onChange
    this.onObjectSelect = onObjectSelect
    this.isActive = false
    this.isVisible = true
    this.type = "ROIBox"
    this.date = date
    this.fontSize = fontSize
    this.startingPoint = {
      x: 0,
      y: 0,
    }
    this.eventsBound = false
    this.color = color
    this.ROIBox = new fabric.Rect({
      id: id,
      type: "ROIBox",
      left: left || 0,
      top: top || 0,
      width: width || 200,
      height: height || 112.5,
      lockScalingFlip: true,
      originX: "left",
      originY: "top",
      fill: `${color}33` || "rgba(149,255,26,0.1)",
      transparentCorners: false,
      hasRotatingPoint: false,
      borderColor: color || "#67ff00",
      cornerColor: color || "rgba(108,255,14,0.76)",
      cornerStrokeColor: "#333",
      cornerSize: 12,
    })
    this.enableMiddleControls = enableMiddleControls

    this.ROIBox.setControlsVisibility({
      mb: this.enableMiddleControls,
      mr: this.enableMiddleControls,
      ml: this.enableMiddleControls,
      mt: this.enableMiddleControls,
      mtr: false,
    })
    this.textBox = new fabric.Text(this.name, {
      canvas: this.canvas,
      top: top ? top - this.fontSize : 0,
      left: left || 0,
      selectable: false,
      fontFamily: "Roboto",
      fontSize: 26,
      fill: color,
      stroke: "black",
      fontWeight: 500,
    })

    this.draw()
    this.bindEvents()

    return this
  }

  setActive(isActive) {
    isActive && this.canvas.setActiveObject(this.ROIBox)
    this.isActive = isActive
  }

  bindEvents() {
    this.ROIBox.on("mousedown", () => {
      this.onObjectSelect()
      this.setActive(true)
    })

    this.canvas.on("object:moving", (options) => {
      if (options.target?.type !== "ROIBox" || !this.isActive) {
        return
      }
      this.repaintTextBox()
    })

    this.canvas.on("object:scaling", (options) => {
      if (options.target?.type !== "ROIBox" || !this.isActive) {
        return
      }
      this.repaintTextBox()
    })

    this.canvas.on("mouse:move", (options) => {
      if (!this.isDrawing || !this.isActive) return
      const pointer = this.canvas.getPointer(options.e)

      this.ROIBox.set({
        left: Math.min(pointer.x, this.startingPoint.x),
        top: Math.min(pointer.y, this.startingPoint.y),
        width: Math.abs(pointer.x - this.startingPoint.x),
        height: Math.abs(pointer.y - this.startingPoint.y),
      })

      this.repaintTextBox()
      this.canvas.renderAll()
      this.onChange()
    })

    this.canvas.on("mouse:up", () => {
      if (!this.isActive) {
        return
      }
      this.isDrawing = false
      this.ROIBox.setCoords()
      this.onChange()
    })
  }

  repaintTextBox() {
    this.textBox.set({
      left: this.ROIBox.left,
      top: this.ROIBox.top - this.fontSize,
    })
  }

  setName(name) {
    if (!name) {
      return
    }
    this.name = name
    this.textBox.set({
      text: name,
    })
    this.canvas.renderAll()
  }

  containInCanvas(e) {
    const obj = e.target

    if (obj.type !== "ROIBox") {
      return
    }

    obj.w = obj.getScaledWidth()
    obj.h = obj.getScaledHeight()

    const overflow = {
      top: obj.top < 0,
      left: obj.left < 0,
      right: obj.left + obj.w > this.canvas.getWidth(),
      bottom: obj.top + obj.h > this.canvas.getHeight(),
    }

    if (!(overflow.top || overflow.right || overflow.bottom || overflow.left)) {
      return
    }

    if (overflow.top) {
      obj.top = 0
    }
    if (overflow.left) {
      obj.left = 0
    }
    if (overflow.bottom) {
      obj.top = this.canvas.getHeight() - obj.h
    }
    if (overflow.right) {
      obj.left = this.canvas.getWidth() - obj.w
    }

    if (e.transform.action === "drag") {
      obj.scaleX = obj._stateProperties.scaleX
      obj.scaleY = obj._stateProperties.scaleY
    }

    obj.setCoords()
    obj.saveState()
    this.onChange()
  }

  draw() {
    if (!this.eventsBound) {
      this.ROIBox.on("moving", this.onChange)
      this.ROIBox.on("scaling", this.onChange)
      this.canvas.on("object:modified", (e) => this.containInCanvas(e))
      this.eventsBound = true
    }
    this.canvas.add(this.ROIBox)
    this.canvas.add(this.textBox)
    this.canvas.setActiveObject(this.ROIBox)
  }

  toggleVisibility() {
    if (this.isVisible) {
      this.ROIBox.set({
        opacity: 0,
        selectable: false,
        evented: false,
        borderColor: "transparent",
        cornerColor: "transparent",
        cornerStrokeColor: "transparent",
        transparentCorners: true,
      })
      this.textBox.set({
        opacity: 0,
        selectable: false,
        evented: false,
        borderColor: "transparent",
        cornerColor: "transparent",
        cornerStrokeColor: "transparent",
        transparentCorners: true,
      })
      this.isVisible = false
    } else {
      this.ROIBox.set({
        opacity: 1,
        selectable: true,
        evented: true,
        borderColor: this.color,
        cornerColor: this.color,
        cornerStrokeColor: "#333",
        transparentCorners: false,
      })
      this.textBox.set({
        opacity: 1,
        selectable: true,
        evented: true,
        borderColor: this.color,
        cornerColor: this.color,
        cornerStrokeColor: "#333",
        transparentCorners: false,
      })
      this.isVisible = true
    }

    this.canvas.renderAll()
  }
}

<template>
  <div class="roi-table">
    <v-data-table :headers="headers" :items="items" hide-default-footer>
      <template #[`item.visibility`]="{ item }">
        <div class="d-flex">
          <div
            style="cursor: pointer"
            class="mr-1"
            @click="onVisibilityToggle(item)"
          >
            <v-icon>
              {{
                visibility[item.id] === false
                  ? "far fa-eye-slash"
                  : "far fa-eye"
              }}
            </v-icon>
          </div>
          <div style="cursor: pointer" @click="onRoiDetailsEdit(item)">
            <v-icon> fa-pencil-alt </v-icon>
          </div>
        </div>
      </template>
      <template #[`item.name`]="{ item }">
        <div
          :class="`roi-table__item__text roi-table__item--color-${item.color} ${
            item.isActive ? 'active' : ''
          } d-flex justify-center w-100`"
        >
          <v-chip small outlined class="w-90" label>
            {{ item.name }}
          </v-chip>
        </div>
      </template>
      <template #[`item.roiType`]="{ item }">
        <div :class="`roi-table__item__text d-flex justify-center w-100`">
          {{ getRoiType(item) }}
        </div>
      </template>
      <template #[`item.directionFilter`]="{ item }">
        <div :class="`roi-table__item__text d-flex justify-center w-100`">
          {{ item.directionFilter }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { RoiTypes } from "@/components/constants.js"
export default {
  name: "RoiTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visibility: {},
      value: "",
      headers: [
        {
          value: "visibility",
          text: "",
          width: 20,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          width: 200,
        },
        {
          value: "date",
          text: "Date",
          sortable: true,
          width: 50,
        },
        {
          value: "roiType",
          text: "Roi Type",
          width: 50,
        },
        {
          value: "directionFilter",
          text: "Direction",
          width: 50,
        },
      ],
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        const visibility = this.visibility
        this.visibility = items.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]:
              visibility[item.id] !== undefined ? visibility[item.id] : true,
          }
        }, {})
      },
    },
  },
  methods: {
    onVisibilityToggle(event) {
      this.$emit("roi-toggle", event.date)
      this.visibility[event.id] = !this.visibility[event.id]
    },
    onRoiDetailsEdit(event) {
      this.$emit("roi-edit", event)
    },
    getRoiType(item) {
      return RoiTypes.find((roi) => roi.value === item?.roiType)?.name
    },
  },
}
</script>

<style lang="scss">
$item-colors: (
  0: #735cb0,
  1: #00a4ef,
  2: #6ab43e,
  3: #e89d41,
  4: #fd4084,
  5: #3c5cff,
);
$border-color: #333;

@each $index, $color in $item-colors {
  .roi-table__item--color-#{$index} {
    color: lighten($color, 10%) !important;
    .v-chip {
      border-color: $color;
      color: $color;
    }
  }
  .active {
    &.roi-table__item--color-#{$index} {
      color: $color !important;
      &.roi-table__item__text > .v-chip.v-chip--no-color.v-chip--outlined {
        background-color: rgba($color, 0.2) !important;
      }
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.roi-table {
  &__item {
    cursor: pointer;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    &__text {
      &.active {
        font-weight: 800;
      }
    }
  }
}
</style>

<style>
.toi-table .v-data-table table {
  border-collapse: collapse;
}
.roi-table .v-data-table table tbody td {
  padding: 0 !important;
}
</style>

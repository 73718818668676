<template>
  <v-sheet tile class="settings pt-2">
    <ECol v-if="canvas" cols="8" class="settings__toolbar py-0 w-70">
      <ERow>
        <v-item-group mandatory class="settings__actions">
          <ERow no-gutters class="pt-1 pl-2">
            <ECol
              v-for="ctrl in controls"
              :key="ctrl.type"
              cols="2"
              class="px-2 py-0"
            >
              <v-item>
                <v-tooltip transition="fade-transition" bottom>
                  <template #activator="{ on }">
                    <v-btn
                      small
                      :color="ctrl.active ? 'primary' : 'default'"
                      v-on="on"
                      @click="setActiveObject(ctrl.type)"
                    >
                      <v-icon :style="ctrl.style"> {{ ctrl.icon }} </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ ctrl.label }}</span>
                </v-tooltip>
              </v-item>
            </ECol>
          </ERow>
        </v-item-group>

        <!-- Action buttons -->
        <div class="ml-auto pt-1">
          <div class="pr-2">
            <v-btn
              class="ml-2"
              color="primary"
              :disabled="!Object.values(editedROIs).length"
              @click="saveNoteDialog = true"
            >
              Save
            </v-btn>
          </div>
        </div>

        <!-- save dialog -->
        <v-dialog v-model="saveNoteDialog" max-width="450">
          <v-card>
            <v-card-text>
              <div class="subtitle-1 py-3">
                Please, explain your modification!
              </div>
              <v-text-field
                v-model="saveNote"
                label="Note"
                dense
                hide-details
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="saveNoteDialog = false">
                Close
              </v-btn>

              <v-btn
                :disabled="!saveNote"
                color="primary"
                @click="saveChanges()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </ERow>
    </ECol>

    <ERow
      v-else-if="!canvas && !imageLoaded"
      class="d-flex align-center justify-center h-100"
    >
      <EvercamLoadingAnimation />
    </ERow>

    <ERow v-show="imageLoaded" class="pa-0 ma-0 fill-height" justify="between">
      <!--SNAPSHOT-->
      <ECol cols="8" class="snapshot pr-2">
        <div v-resize-observer="onResize" class="snapshot__container h-70-vh">
          <v-overlay :absolute="true" :value="roiOverlay">
            <EvercamLoadingAnimation :verify-theme-mode="false" />
          </v-overlay>
          <EZoomableImg
            ref="snapshotImg"
            :src="roiStore.snapshotUrl"
            class="snapshot"
            style="min-height: 400px"
            contain
            :pan="false"
            :overlay-style="{ zIndex: 1 }"
            :is-widget="accountStore.isWidget"
            @load="onImageLoad"
          >
            <template #imageOverlay>
              <canvas id="snapshot-canvas" class="settings__canvas"></canvas>
            </template>
          </EZoomableImg>
        </div>
      </ECol>

      <ECol
        v-if="roiStore.ROI && roiStore.snapshotUrl"
        cols="4"
        class="settings__right-panel"
      >
        <v-card elevation="0">
          <ERow class="mx-0 py-2" justify="between" align="center">
            <v-combobox
              v-if="gateReportStore.selectedCamera"
              v-model="gateReportStore.selectedCamera"
              :items="gateReportStore.cameras"
              dense
              hide-details
              outlined
            >
              <template #selection="{ item }">
                <span> {{ item.name }}({{ item.exid }}) </span>
              </template>
              <template #item="{ item }">
                <span>{{ item.name }}({{ item.exid }})</span>
              </template>
            </v-combobox>
          </ERow>
          <ERow class="mx-0 pb-2">
            <v-btn
              elevaion="3"
              class="w-100"
              color="primary"
              @click="roiDialog = true"
            >
              Add a new ROI
              <v-icon small dark class="ml-2"> fa-plus </v-icon>
            </v-btn>
          </ERow>
          <v-tabs v-model="tab" centered fixed-tabs class="custom-tabs">
            <v-tab> ROI Settings </v-tab>
            <v-tab> Recording </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <ERow class="mx-0">
                  <ECol class="px-0">
                    <RoiTable
                      :items="tableItems"
                      @roi-toggle="onRoiToggle"
                      @roi-edit="onRoiEdit"
                    />
                  </ECol>
                </ERow>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <SnapshotDateTimeCalendar
                  v-model="snapshotCurrentDate"
                  :selected-camera="gateReportStore.selectedCamera"
                  @fetch-error="errorOnRequest"
                  @first-frame="
                    roiStore.snapshotUrl = snapshotStore.oldestImageJson.data
                  "
                  @last-frame="
                    roiStore.snapshotUrl = snapshotStore.latestImageJson.data
                  "
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </ECol>
    </ERow>
    <!-- Edit dialog -->
    <v-dialog
      v-model="roiDialog"
      persistent
      max-width="500px"
      @keydown.esc="onNewRoiDismiss"
    >
      <v-card>
        <v-card-title class="lighten-2 pb-0 pl-5"> Add a new ROI </v-card-title>
        <v-card-text class="pa-0">
          <v-container>
            <ERow>
              <ECol cols="12" sm="12" md="12" class="px-5">
                <!-- Name -->
                <v-text-field
                  v-model="newRoiName"
                  dense
                  outlined
                  label="Name"
                ></v-text-field>
                <!-- Date -->
                <v-dialog
                  v-if="!isEditMode"
                  ref="dateDialog"
                  v-model="dateDialog"
                  :return-value.sync="newRoiDate"
                  persistent
                  width="310px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="newRoiDate"
                      label="Date"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newRoiDate"
                    scrollable
                    :allowed-dates="isDateAllowed"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateDialog = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateDialog.save(newRoiDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-select
                  v-model="newRoiType"
                  outlined
                  :items="roiTypes"
                  item-text="name"
                  item-value="value"
                  label="Roi Type"
                ></v-select>
                <v-select
                  v-model="newDirectionsType"
                  outlined
                  :items="directionTypes"
                  item-value="value"
                  item-text="type"
                  label="Direction Type"
                ></v-select>
                <ERow justify="end" class="pr-3">
                  <v-btn
                    text
                    color="primary"
                    class="mr-2"
                    @click="onNewRoiDismiss"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="
                      onRoiCreate(
                        newRoiName,
                        newRoiDate,
                        newRoiType,
                        newDirectionsType
                      )
                    "
                  >
                    Save
                  </v-btn>
                </ERow>
              </ECol>
            </ERow>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import SnapshotDateTimeCalendar from "@/components/SnapshotDateTimeCalendar"
import { fabric } from "fabric"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import ArrowLine from "@/components/roiSettings/fabric/arrowLine"
import ROIBox from "@/components/roiSettings/fabric/roiBox"
import RoiTable from "@/components/roiSettings/RoiTable"
import { RoiTypes, RoiDirectionTypes } from "@/components/constants.js"
import { mapStores } from "pinia"
import { useSnapshotStore } from "@/stores/snapshots"
import { useGateReportStore } from "@/stores/gateReport"
import { useAccountStore } from "@/stores/account"
import { useRoiStore } from "@/stores/roi"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { RoiType } from "@evercam/shared/types"

export default {
  name: "Roi",
  components: {
    RoiTable,
    SnapshotDateTimeCalendar,
    EvercamLoadingAnimation,
  },
  async middleware({ params }) {
    const gateReportStore = useGateReportStore()
    const selectedCamera = gateReportStore.selectedCamera
    if (selectedCamera?.exid !== params.exid) {
      await gateReportStore.init(params.exid)
    }
  },
  async asyncData({ params }) {
    useBreadcrumbStore().breadcrumbs = [
      {
        name: "Gate report",
        href: "/gate-report",
      },
      {
        name: params.exid,
        href: `/gate-report/${params.exid}/events`,
      },
      {
        name: "ROI",
      },
    ]
  },
  data() {
    return {
      snapshotCurrentDate: new Date().toISOString(),
      img: null,
      canvas: null,
      ROIBox: null,
      direction: null,
      line: null,
      imageLoaded: false,
      roiDialog: false,
      dateDialog: false,
      saveNoteDialog: false,
      saveNote: "",
      newRoiName: "",
      newRoiDate: "",
      newRoiType: "",
      newDirectionsType: "",
      roiTypes: RoiTypes,
      directionTypes: RoiDirectionTypes,
      roiOverlay: true,
      editedItem: {},
      controls: [
        {
          type: "ROIBox",
          label: "ROI Box",
          icon: "fa-vector-square",
          active: true,
        },
        {
          type: "direction",
          label: "Direction arrow",
          icon: "fa-long-arrow-alt-right",
          style: "transform: rotate(-45deg);",
          active: false,
        },
        {
          type: "line",
          label: "Crossing line",
          icon: "fa-slash",
          style: "transform: rotate(-45deg);",
          active: false,
        },
      ],
      tab: null,
      canvasObjects: {},
      editedROIs: {},
      selectedDate: "",
      isEditMode: false,
      colors: {
        0: "#7037ff",
        1: "#3ed8ff",
        2: "#93ff50",
        3: "#ffa200",
        4: "#ff005d",
        5: "#0029ff",
        6: "#ff0000",
      },
      count: 1,
      gateName: "",
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useGateReportStore,
      useAccountStore,
      useRoiStore
    ),
    enabledDates() {
      if (this.count < 1) {
        return []
      }

      return Object.keys(this.roiStore.ROIList).sort((a, b) =>
        this.$moment(a).isAfter(this.$moment(b)) ? 1 : -1
      )
    },
    latestDate() {
      if (this.enabledDates.length) {
        return this.enabledDates.slice(-1)[0]
      }

      return this.formatDate(this.selectedDate, "YYYY-MM-DD")
    },
    tableItems() {
      return Object.keys(this.roiStore.ROIList).map((date, index) => {
        const ROIA = this.roiStore.ROIList[date]

        return {
          id: ROIA.rectangle?.id,
          name: ROIA.rectangle?.name || `No name (${date})`,
          color: index,
          date: date,
          isActive: this.selectedDate === date,
          roiType: ROIA.rectangle?.roiType,
          directionFilter: ROIA.rectangle?.directionFilter,
        }
      })
    },
  },
  watch: {
    "gateReportStore.selectedCamera"() {
      this.fetchRois()
    },
    snapshotCurrentDate(date) {
      this.roiOverlay = true
      this.getSnapshot(date)
    },
  },
  async created() {
    await this.snapshotStore.updateFrames({
      camera: this.gateReportStore.selectedCamera.exid,
      apiKey: this.gateReportStore.selectedCamera.userApiKey,
      apiId: this.gateReportStore.selectedCamera.userApiId,
    })
  },
  beforeMount() {
    this.fetchRois()
  },
  methods: {
    getTimezone(timezone) {
      return timezone || "Europe/Dublin"
    },
    setLatesSnapshot() {
      this.currentSnapshot = {
        createdAt: this.snapshotStore.afterdate,
        data: this.afterimage,
        notes: "",
      }
    },
    errorOnRequest(text, error) {
      this.$notifications.error({ text, error })
      this.setNoSnapshot()
    },
    setNoSnapshot() {
      this.currentSnapshot = {
        createdAt: null,
        data: this.noSnapshot,
        notes: "",
      }
    },
    isLatestSnapshotDate(date) {
      return (
        Math.abs(
          this.$moment
            .duration(
              this.$moment(date).diff(
                this.snapshotStore.latestImageJson?.createdAt
              )
            )
            .asHours()
        ) < 1
      )
    },
    isOldestSnapshotDate(date) {
      return (
        Math.abs(
          this.$moment
            .duration(
              this.$moment(date).diff(
                this.snapshotStore.oldestImageJson?.createdAt
              )
            )
            .asHours()
        ) < 1
      )
    },
    getSnapshot(timestamp) {
      if (this.isLatestSnapshotDate(timestamp)) {
        this.roiOverlay = false
        this.roiStore.snapshotUrl = this.snapshotStore.latestImageJson.data

        return
      } else if (this.isOldestSnapshotDate(timestamp)) {
        this.roiOverlay = false
        this.roiStore.snapshotUrl = this.snapshotStore.oldestImageJson.data

        return
      }
      EvercamApi.recordings
        .nearest(this.gateReportStore.selectedCamera.exid, `${timestamp}`, {
          apiKey: this.gateReportStore.selectedCamera?.userApiKey,
          apiId: this.gateReportStore.selectedCamera?.userApiId,
        })
        .then((response) => {
          if (response.snapshots.length !== 0) {
            this.roiStore.snapshotUrl = response.snapshots[0].data
            this.roiOverlay = false
          }
        })
        .finally(() => {})
    },
    async fetchRois() {
      this.roiOverlay = true
      await this.roiStore.fetchROIs({
        projectExid: this.gateReportStore.selectedCamera.projectExid,
        camerasExid: [this.gateReportStore.selectedCamera.exid],
        isactive: true,
      })
      this.selectedDate = this.latestDate
      await this.updateSnapshot()
    },
    async reset() {
      this.canvas?.clear()
      this.canvas?.dispose()
      this.selectedDate = this.latestDate
      if (!this.roiStore.ROIList[this.selectedDate]) {
        this.roiStore.updateROIList({
          date: this.selectedDate,
          change: this.roiStore.defaultROI,
        })
      }
      this.roiStore.snapshotUrl = ""
      this.img = null
      this.canvas = null
      this.canvasObjects = {}
      this.imageLoaded = false
    },
    onResize() {
      this.$setTimeout(() => {
        if (this.canvas) {
          this.canvas?.clear()
          this.canvas?.dispose()
          this.canvas = null
          this.renderCanvas()
        }
      }, 500)
    },
    async updateSnapshot() {
      if (this.canvas) {
        this.reset()
      }
      try {
        const { data } = await EvercamApi.recordings.latest(
          this.gateReportStore.selectedCamera.exid,
          {
            apiId: this.gateReportStore.selectedCamera.userApiId,
            apiKey: this.gateReportStore.selectedCamera.userApiKey,
          }
        )
        this.roiStore.snapshotUrl = data
      } catch (error) {
        this.$notifications.error({
          text: "Failed to fetch a snapshot",
          error,
        })
      } finally {
        this.roiOverlay = false
      }
    },
    onImageLoad() {
      if (this.canvas) {
        return
      }
      this.imageLoaded = true
      this.$setTimeout(() => this.$nextTick(this.renderCanvas), 200)
    },
    renderCanvas() {
      this.img = this.$refs.snapshotImg?.$refs?.image
      if (!this.canvas) {
        this.canvas = new fabric.Canvas("snapshot-canvas", {
          uniScaleTransform: true,
          width: this.img.offsetWidth,
          height: this.img.offsetHeight,
          stateful: true,
          selection: false,
          preserveObjectStacking: true,
          perPixelTargetFind: true,
          targetFindTolerance: 15,
        })
        this.canvas.toObject = () => ({})
        window.canvas = this.canvas
      }
      Object.entries(this.roiStore.ROIList).forEach((object, index) => {
        this.drawObjects(object, index)
      })
      const currentRect = this.canvasObjects[this.selectedDate]?.ROIBox

      if (currentRect?.ROIBox) {
        this.canvas.setActiveObject(currentRect.ROIBox)
      }

      const oldCanvas = {
        width: this.canvas.width,
        height: this.canvas.height,
      }

      this.canvas.setWidth(this.img.offsetWidth)
      this.canvas.setHeight(this.img.offsetHeight)
      this.canvas.renderAll()
      this.canvas.setZoom(this.canvas.width / oldCanvas.width)

      this.canvas.on("mouse:down", (options) => {
        options?.target && this.setActiveObject(options.target.type)
      })
    },
    updateObjectsROIs(date) {
      this.initGateName()
      this.selectedDate = date || this.latestDate
      const ROI = this.roiStore.ROIList[this.selectedDate]

      if (!this.roiStore.ROI) {
        this.roiStore.updateROI(ROI)

        return
      }

      let objects = this.canvasObjects[this.selectedDate]
      if (!objects) {
        this.drawObjects(
          [this.selectedDate, ROI || this.roiStore.defaultROI],
          Object.keys(this.roiStore.ROIList).length
        )
        objects = this.canvasObjects[this.selectedDate]
      }
      let newROI
      if (ROI.rectangle?.roiType == RoiType.Gate) {
        newROI = {
          rectangle: {
            cameraex: this.gateReportStore.selectedCamera.exid,
            name: this.gateName,
            x1: objects.ROIBox.ROIBox.left / this.canvas.width,
            x2:
              (objects.ROIBox.ROIBox.left +
                objects.ROIBox.ROIBox.getScaledWidth()) /
              this.canvas.width,
            y1: objects.ROIBox.ROIBox.top / this.canvas.height,
            y2:
              (objects.ROIBox.ROIBox.top +
                objects.ROIBox.ROIBox.getScaledHeight()) /
              this.canvas.height,
          },
          direction: {
            cameraex: this.gateReportStore.selectedCamera.exid,
            name: this.gateName,
            x1: objects.direction.shape.x1 / this.canvas.width,
            y1: objects.direction.shape.y1 / this.canvas.height,
            x2: objects.direction.shape.x2 / this.canvas.width,
            y2: objects.direction.shape.y2 / this.canvas.height,
          },
          line: {
            cameraex: this.gateReportStore.selectedCamera.exid,
            name: this.gateName,
            x1: objects.line.shape.x1 / this.canvas.width,
            y1: objects.line.shape.y1 / this.canvas.height,
            x2: objects.line.shape.x2 / this.canvas.width,
            y2: objects.line.shape.y2 / this.canvas.height,
          },
        }
      } else {
        newROI = {
          rectangle: {
            cameraex: this.gateReportStore.selectedCamera.exid,
            name: this.gateName,
            x1: objects.ROIBox.ROIBox.left / this.canvas.width,
            x2:
              (objects.ROIBox.ROIBox.left +
                objects.ROIBox.ROIBox.getScaledWidth()) /
              this.canvas.width,
            y1: objects.ROIBox.ROIBox.top / this.canvas.height,
            y2:
              (objects.ROIBox.ROIBox.top +
                objects.ROIBox.ROIBox.getScaledHeight()) /
              this.canvas.height,
          },
        }
      }

      this.roiStore.updateROI(newROI)
      this.roiStore.updateROIList({
        date: this.selectedDate,
        change: newROI,
      })
    },
    drawObjects([date, ROI], index) {
      const ROIROIs = ROI.rectangle || {}
      const lineROIs = ROI.line || {}
      const directionROIs = ROI.direction || {}
      const color = this.colors[index]
      const ROIBoxObj = new ROIBox({
        id: ROIROIs.id,
        name: ROIROIs.name || `No name (${date})`,
        canvas: this.canvas,
        left: ROIROIs.x1 * this.canvas.width,
        top: ROIROIs.y1 * this.canvas.height,
        width: (ROIROIs.x2 - ROIROIs.x1) * this.canvas.width,
        height: (ROIROIs.y2 - ROIROIs.y1) * this.canvas.height,
        onChange: () => {
          this.editedROIs[date] = true
          this.updateObjectsROIs(date)
        },
        onObjectSelect: () => this.selectDate(date),
        date: date,
        color,
        enableMiddleControls: ROI.rectangle?.roiType === RoiType.AnprContext,
      })
      if (ROI.rectangle?.roiType === RoiType.Gate) {
        const directionObj = new ArrowLine({
          type: "direction",
          canvas: this.canvas,
          x1: directionROIs?.x1 * this.canvas.width,
          y1: directionROIs?.y1 * this.canvas.height,
          x2: directionROIs?.x2 * this.canvas.width,
          y2: directionROIs?.y2 * this.canvas.height,
          onChange: () => {
            this.editedROIs[date] = true
            this.updateObjectsROIs(date)
          },
          onObjectSelect: () => this.selectDate(date),
          date: date,
          color,
        })

        const lineObj = new ArrowLine({
          type: "line",
          canvas: this.canvas,
          x1: lineROIs?.x1 * this.canvas.width,
          y1: lineROIs?.y1 * this.canvas.height,
          x2: lineROIs?.x2 * this.canvas.width,
          y2: lineROIs?.y2 * this.canvas.height,
          onChange: () => {
            this.editedROIs[date] = true
            this.updateObjectsROIs(date)
          },
          onObjectSelect: () => this.selectDate(date),
          date: date,
          color,
        })
        this.canvasObjects[date] = {
          ROIBox: ROIBoxObj,
          line: lineObj,
          direction: directionObj,
        }
      } else {
        this.canvasObjects[date] = {
          ROIBox: ROIBoxObj,
        }
      }
      this.setActiveObject("ROIBox")
      this.updateGateName(date, ROIROIs.name)
    },
    async saveChanges() {
      for (let date of Object.keys(this.roiStore.ROIList)) {
        if (this.roiStore.ROIList[date]?.rectangle?.name) {
          await this.roiStore.upsertROI({
            date,
            cameraId: this.gateReportStore.selectedCamera.exid,
            ROI: this.roiStore.ROIList[date],
            id: this.roiStore.ROIList[date]?.rectangle?.id,
            note: this.saveNote,
            userEmail: this.accountStore.email,
          })
        }
      }
      this.saveNoteDialog = false
      this.saveNote = ""
      this.$notifications.success(
        `ROI coordinates were set successfully for dates: ${JSON.stringify(
          Object.keys(this.editedROIs)
        )}`
      )
      await this.fetchRois()
    },
    getObject(type) {
      const objects =
        this.canvasObjects[this.getClosestDate(this.selectedDate)] || {}

      return objects[type]
    },
    setActiveObject(target) {
      this.controls.forEach((ctrl) => {
        const object = this.getObject(ctrl.type)
        if (!object) {
          return
        }

        const isActive = object.type === target
        object.setActive(isActive)
        ctrl.active = isActive
      })
    },
    getClosestDate(date) {
      return Object.keys(this.roiStore.ROIList)
        .sort((a, b) => (this.$moment(a).isBefore(this.$moment(b)) ? 1 : -1))
        .find((d) => this.$moment(d).isSameOrBefore(this.$moment(date)))
    },
    selectDate(date) {
      Object.values(this.canvasObjects).forEach((objects) => {
        Object.values(objects).forEach((obj) => obj.setActive(false))
      })

      this.selectedDate = date
      this.roiStore.ROI = null
      this.updateObjectsROIs(date)
      const targetObjects = this.canvasObjects[date]

      if (!targetObjects?.ROIBox) {
        return
      }

      targetObjects.ROIBox.setActive(true)
      this.canvas.setActiveObject(targetObjects.ROIBox.ROIBox)
      this.canvas.renderAll()
    },
    initGateName() {
      const ROI =
        this.roiStore.ROIList[this.selectedDate] || this.roiStore.defaultROI
      this.gateName = ROI.rectangle?.name
    },
    updateGateName(date, val) {
      this.selectDate(date)
      const ROI = this.roiStore.ROIList[date] || this.roiStore.defaultROI
      this.roiStore.updateROIList({
        date: date || this.selectedDate,
        change: {
          rectangle: {
            ...ROI.rectangle,
            name: val,
          },
          line: {
            ...ROI.line,
            name: val,
          },
          direction: {
            ...ROI.direction,
            name: val,
          },
        },
      })
      this.gateName = val
      this.editedROIs[this.selectedDate] = true
      this.canvasObjects[this.selectedDate]?.ROIBox?.setName(val)
    },
    getObjectById(id) {
      return this.canvas.getObjects().find((obj) => obj.id === id)
    },
    onRoiSelect({ id, date }) {
      if (!this.roiStore.ROIList[date]) {
        return
      }

      if (!this.getObjectById(id)) {
        this.drawObjects(
          [date, this.roiStore.ROIList[date]],
          Object.keys(this.roiStore.ROIList).length
        )
      }

      this.selectDate(date)
    },
    onRoiCreate(name, date, type, directionFilter) {
      if (this.isEditMode) {
        date = this.editedItem.date
        this.selectDate(date)
      }

      const getId = (shape) => {
        if (!this.isEditMode || !this.editedItem) {
          return
        }

        return this.roiStore.ROIList[this.editedItem.date][shape].id
      }
      this.roiStore.updateROIList({
        date: date || this.editedItem.date,
        change: {
          rectangle: {
            id: getId("rectangle"),
            name: name || `No name - ${date}`,
            roiType: type,
            directionFilter: directionFilter,
          },
          line: {
            id: getId("line"),
            name: name || `No name - ${date}`,
            roiType: type,
            directionFilter: directionFilter,
          },
          direction: {
            id: getId("direction"),
            name: name || `No name - ${date}`,
            roiType: type,
            directionFilter: directionFilter,
          },
        },
      })
      if (!this.canvasObjects[date]) {
        this.drawObjects(
          [date, this.roiStore.ROIList[date] || this.roiStore.defaultROI],
          Object.keys(this.roiStore.ROIList).length
        )
      }
      if (this.isEditMode) {
        this.gateName = name
        this.editedROIs[date] = true
        this.canvasObjects[date]?.ROIBox?.setName(name)
      }
      this.onRoiSelect({
        id: getId("rectangle"),
        date: date || this.editedItem.date,
      })
      this.$nextTick(() => this.count++)
      this.onNewRoiDismiss()
    },
    onRoiToggle(date) {
      Object.values(this.canvasObjects[date]).forEach((obj) => {
        obj.toggleVisibility()
      })
    },
    onRoiEdit(item) {
      this.editedItem = item
      this.newRoiName = item.name
      this.newRoiType = item.roiType
      this.newDirectionsType = item.directionFilter
      this.isEditMode = true
      this.roiDialog = true
    },
    onNewRoiDismiss() {
      this.editeditem = {}
      this.isEditMode = false
      this.roiDialog = false
      this.newRoiDate = ""
      this.newRoiName = ""
    },
    isDateAllowed(date) {
      const existingDates = Object.keys(this.roiStore.ROIList)

      return !existingDates.includes(date)
    },
  },
}
</script>

<style lang="scss">
.settings {
  &__toolbar {
    z-index: 2;
    position: relative;
  }

  &__actions {
    min-width: 360px;
  }

  &__right-panel {
    margin-top: -48px;
  }

  .snapshot {
    &__container {
      position: relative;

      & > .canvas-container {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }

  &__canvas {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 99;
  }
}
</style>

import { fabric } from "fabric"

const Arrow = fabric.util.createClass(fabric.Line, {
  _getCacheCanvasDimensions() {
    var dim = this.callSuper("_getCacheCanvasDimensions")
    dim.width += 15
    dim.height += 15

    return dim
  },
  _render(ctx) {
    this.callSuper("_render", ctx)
    ctx.save()

    const arrowHeadSize = 7
    const xDiff = this.x2 - this.x1
    const yDiff = this.y2 - this.y1
    const angle = Math.atan2(yDiff, xDiff)
    ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2)
    ctx.rotate(angle)
    ctx.beginPath()
    ctx.moveTo(arrowHeadSize, 0)
    ctx.lineTo(-arrowHeadSize, arrowHeadSize)
    ctx.lineTo(-arrowHeadSize, -arrowHeadSize)
    ctx.closePath()
    ctx.fillStyle = this.stroke
    ctx.fill()
    ctx.restore()
  },
})

export default class ArrowLine {
  constructor({
    canvas,
    x1,
    x2,
    y1,
    y2,
    onChange,
    onObjectSelect,
    type,
    color,
    date,
    ...options
  }) {
    this.shape = null
    this.isDrawing = false
    this.canvas = canvas
    this.onChange = onChange
    this.onObjectSelect = onObjectSelect
    this.type = type || "arrow"
    this.rendererClass = this.type === "line" ? fabric.Line : Arrow
    this.isActive = false
    this.isVisible = true
    this.isHovered = false
    this.date = date
    this.options = {
      type: this.type,
      hasBorders: false,
      stroke: color || "#6cff0e",
      strokeWidth: 3,
      transparentCorners: true,
      hasRotatingPoint: false,
      cornerStyle: "circle",
      borderColor: color || "#72ff00",
      cornerColor: "#ff00ec",
      cornerStrokeColor: "#333",
      cornerSize: 20,
      lockMovementX: true,
      lockMovementY: true,
      hoverCursor: "pointer",
      ...options,
    }
    this.bindEvents()
    this.draw([x1, y1, x2, y2])
    this.canvas.renderAll()

    return this
  }

  setActive(isActive) {
    isActive && this.canvas.setActiveObject(this.shape).renderAll()
    this.isActive = isActive
  }

  draw(points) {
    if (this.shape) {
      this.canvas.remove(this.shape)
    }
    this.shape = new this.rendererClass(points, this.options)
    this.shape.canvas = this.canvas
    this.shape.on("mousedown", (e) => {
      this.onObjectSelect && this.onObjectSelect()
      this.setActive(true)
      e.target.canvas = this.canvas
    })
    this.canvas.add(this.shape).setActiveObject(this.shape).renderAll()
    this.setControlVisibility()
  }

  bindEvents() {
    this.canvas.on("mouse:down", (options) => {
      if (this.canvas._currentTransform?.target) {
        this.canvas._currentTransform.target.canvas = this.canvas
      }
      if (
        !this.isActive ||
        (options.target && options.target?.type !== this.type)
      ) {
        return
      }

      if (
        options.target &&
        options.transform &&
        options.transform?.action !== "scale"
      ) {
        this.setActive(false)

        return
      }

      this.isDrawing = true
      this.reverse = false
      const pointer = this.canvas.getPointer(options.e)
      let target = {}

      if (options.target) {
        target = options.target
        const distanceToArrowStart = Math.sqrt(
          (pointer.x - target.x1) ** 2 + (pointer.y - target.y1) ** 2
        )
        const distanceToArrowEnd = Math.sqrt(
          (pointer.x - target.x2) ** 2 + (pointer.y - target.y2) ** 2
        )
        this.reverse = distanceToArrowStart < distanceToArrowEnd
      }

      const points = [
        target.x1 || pointer.x,
        target.y1 || pointer.y,
        target.x2 || pointer.x,
        target.y2 || pointer.y,
      ]

      this.draw(points)
      this.setControlVisibility()
    })

    this.canvas.on("mouse:move", (options) => {
      if (!this.isDrawing || !this.isActive) return
      const pointer = this.canvas.getPointer(options.e)
      this.setControlVisibility(true)

      let transform = this.reverse
        ? { x1: pointer.x, y1: pointer.y }
        : { x2: pointer.x, y2: pointer.y }
      this.shape.set(transform)
      this.canvas.renderAll()
      this.onChange && this.onChange()
    })

    this.canvas.on("mouse:up", () => {
      if (!this.isDrawing || !this.shape || !this.isActive) {
        return
      }
      this.isDrawing = false
      this.shape.setCoords()
      this.setControlVisibility()
      this.canvas.renderAll()
      this.onChange && this.onChange()
    })
  }

  setControlVisibility(hide) {
    let isCornerAtLineEnd = (corner) => {
      if (!corner) {
        return false
      }

      return (
        Math.min(
          Math.sqrt(
            (corner.x - this.shape.x1) ** 2 + (corner.y - this.shape.y1) ** 2
          ),
          Math.sqrt(
            (corner.x - this.shape.x2) ** 2 + (corner.y - this.shape.y2) ** 2
          )
        ) < 10
      )
    }

    this.shape.setControlsVisibility({
      tl: hide ? false : isCornerAtLineEnd(this.shape.aCoords?.tl),
      tr: hide ? false : isCornerAtLineEnd(this.shape.aCoords?.tr),
      bl: hide ? false : isCornerAtLineEnd(this.shape.aCoords?.bl),
      br: hide ? false : isCornerAtLineEnd(this.shape.aCoords?.br),
      ml: false,
      mt: false,
      mr: false,
      mb: false,
      mtr: false,
    })
  }

  toggleVisibility() {
    if (this.isVisible) {
      this.canvas.remove(this.shape)
      this.isVisible = false
    } else {
      this.canvas.add(this.shape)
      this.isVisible = true
    }

    this.canvas.renderAll()
  }
}
